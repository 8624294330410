/**
 * 微信js-sdk
 * 参考文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html
 */
import wx from 'weixin-js-sdk'
import { $http } from './http'

const wxApi = {
  /**
   * [wxRegister 微信Api初始化]
   * @param  {Function} callback [ready回调函数]
   */
  wxRegister(options) { //data是微信配置信息，options是分享的配置内容
    $http.post('/api/website/token.json', {
      url: location.href
    }).then(data => {
      if(data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: [
            'checkJsApi',
            'updateAppMessageShareData', 
            'updateTimelineShareData'
          ] // 必填，需要使用的JS接口列表
        });
      }
    });
    wx.ready(function() {
      wx.updateTimelineShareData({
        title: options.title, // 分享标题
        link: options.link, // 分享链接
        imgUrl: options.imgUrl, // 分享图标
        desc: options.desc, // 分享描述
        success() {
          // 用户成功分享后执行的回调函数
          options.success();
        },
        cancel() {
          // 用户取消分享后执行的回调函数
          options.error();
        }
      });
      wx.updateAppMessageShareData({
        title: options.title, // 分享标题
        desc: options.desc, // 分享描述
        link: options.link, // 分享链接
        imgUrl: options.imgUrl, // 分享图标
        success() {
          // 用户成功分享后执行的回调函数
          options.success();
        },
        cancel() {
          // 用户取消分享后执行的回调函数
          options.error();
        }
      })
    })
  }
}
export default wxApi