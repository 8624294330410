<template>
  <swiper v-if="![1027, 1029].includes(uccnInfo.id)" :options="swiperOption" class="job-swipe">
    <swiper-slide v-for="(child, key) in list" :key="key" >
      <div :class="rowClass">
        <div v-for="(item, inx) in child" :key="inx">
          <slot :item="item"></slot>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
  <swiper v-else class="job-swipe">
    <swiper-slide>
      <div :class="rowClass">
        <div v-for="(item, inx) in jobList" :key="inx">
          <slot :item="item"></slot>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Col, Row } from 'vant'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    swiper,
    swiperSlide
  },
  props: {
    jobList: {
      type: Array,
      default: () => []
    },
    displayColumn: {
      type: Number,
      default: 3
    },
    rowClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    list() {
      const result = [];
      this.jobList.forEach((item, key) => {
        item.loopKey = key;
      })
      for (let i = 0; i < this.jobList.length; i += this.displayColumn) {
        result.push(this.jobList.slice(i, i + this.displayColumn));
      }
      return result;
    },
    ...mapGetters(['uccnInfo']),
  },
  data(){
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination', // 分页指示器所在的 DOM 元素
          clickable: true // 点击分页指示器切换到对应的 slide
        },
        loop: false,
        touchRatio: 2, // 触摸滑动的顺畅度
        initialSlide: 1, // 默认展示页
        autoplay: true, // 是否自动播放
        speed: 750, // 播放速度
        slidesPerView: 'auto',
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 5, // 模块之间的间隔
        on: {
          slideChangeTransitionEnd: function() {
            // 处理手动操作轮播图后后不再自动轮播
            this.autoplay.start();
          }
        }
      }
    }
	}
}
</script>