<template>
  <van-action-sheet class="text-sheet-wrap" v-model="dialogData.isShow" :title="dialogData.title">
    <div class="text-content">
      {{ OutRefuseType[dialogData.elimateType] }}
    </div>
    <div class="close-btn" @click="dialogData.isShow = false">关闭</div>
  </van-action-sheet>
</template>

<script>
  import './index.scss'
  import { ActionSheet } from 'vant'
  import { OutRefuseType } from '@/enums/EnumRecommend'

  export default {
    components: {
      [ActionSheet.name]: ActionSheet
    },
    props: {
      dialogData: Object
    },
    data() {
      return {
        OutRefuseType
      }
    }
  }
</script>
