<template>
  <div class="container main-page pc-delivery-container">
    <sub-head-navs />
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ name: 'Home', query: { id: uccnInfo.id }, params: {pathMatch}}">招聘首页</el-breadcrumb-item>
        <el-breadcrumb-item>投递进展</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="delivery-table-box">
      <el-table
        v-loading="isLoading"
        class="delivery-table"
        :data="recordList"
        row-key="id"
        style="width: 100%">
        <el-table-column
          prop="jobName"
          label="投递职位"
          :show-overflow-tooltip="true">
          <template slot-scope="scope"><a :href="`/job/detail/${scope.row.jobId}?websiteId=${uccnInfo.id}&recommendId=${scope.row.recommendId}`">{{ scope.row.jobName }}</a></template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="投递时间">
          <template slot-scope="scope">{{ scope.row.createTime | DATE('YYYY-MM-DD') }}</template>
        </el-table-column>
        <el-table-column
          prop="resultName"
          label="当前阶段">
          <template slot-scope="scope">
            {{ scope.row.resultName }}
            <span class="reason" v-if="scope.row.stageId === 3 && scope.row.elimateType">原因：{{ OutRefuseType[scope.row.elimateType]}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="uccnInfo.sysType === 2" label="志愿设置">
          <template slot-scope="{row}">
            <el-radio v-if="totalRecords > 1 && row.lockVolunteerFlag === 1 && row.recomResult !== 3" v-model="firstChoice" :label="row.id" @input="changeDesire">设为第一志愿</el-radio>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <el-pagination
        @current-change="onChangePage"
        :current-page.sync="searchParams.current"
        :page-size="searchParams.pageSize"
        :total="totalRecords"
        :hide-on-single-page="true"
        layout="prev, pager, next">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Toast } from 'vant'
  import { Breadcrumb, BreadcrumbItem, Table, TableColumn, Pagination, Radio } from 'element-ui'
  import { local } from '@/utils'
  import { OutRefuseType } from '@/enums/EnumRecommend'
  import subHeadNavs from '@/components/pc/headNavs'

  export default {
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
      [Radio.name]: Radio,
      subHeadNavs
    },
    data() {
      return {
        OutRefuseType,
        isLoading: false,
        searchParams: {
          current: 1,
          pageSize: 10,
        },
        recordList: [],
        totalRecords: 10,
        firstChoice: ''
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      pathMatch() {
        return local.get('rootPath') || '';
      }
    },
    created() {
      this.queryData();
    },
    methods: {
      queryData(current = 1) {
        this.searchParams.current = current;
        this.isLoading = true;
        this.$http.get('/api/internal/recom/person/delivery/process.json', {params: {
          websiteId: this.uccnInfo.id,
          ...this.searchParams
        }}).then(data => {
          if(data) {
            this.recordList = (data || {}).list || [];
            this.totalRecords = (data || {}).totalRecords || 0;
            // 回显选中值
            this.recordList.forEach(item => {
              if(item.firstChoice) {
                this.firstChoice = item.id;
              }
            });
          }
        }).finally(() => {
          this.isLoading = false;
        });
      },
      onChangePage(index) {
        this.queryData(index);
      },
      changeDesire(val) {
        this.$http.post('/api/internal/recom/person/recommend/first/choice.json', {
          websiteId: this.uccnInfo.id,
          id: val
        }).then(data => {
          if(data) {
            Toast.success('设置成功');
          }
        });
      }
    }
  }
</script>
