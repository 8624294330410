<template>
  <van-row class="h5-self-define-wrap" gutter="30">
    <van-col v-for="(item, key) in moduleData" :class="{'img-all-wrap': imgAll}" :key="key" :span="imgAll ? 12 : 24">
      <!-- 1图片 2视频 3文字 -->
      <div v-if="item.type === 1">
        <van-swipe v-if="item.fileDtoList && item.fileDtoList.length" class="item-img-swipe" :class="{monstrous: uccnInfo.companyId === 22781}" :autoplay="3000" indicator-color="white" lazy-render>
          <van-swipe-item v-for="(ele, index) in item.fileDtoList" :key="index"><img :src="ele.filePath"></van-swipe-item>
        </van-swipe>
      </div>
      <div v-else-if="item.type === 2 && item.fileDtoList && item.fileDtoList.length" class="vide-box">
        <video :ref="`media${item.id}`" :controls="showControls[key]" width="auto" height="640" loop :poster="item.videoPageDto ? item.videoPageDto.filePath : ''"
          :src="item.fileDtoList[0].filePath">
        </video>
        <p class="dialog-mask" :class="{'fade-out': showControls[key] }">
          <i class="h5pp h5pp-icon-audio-pause" @click="onPlay(item, key)"></i>
        </p>
      </div>
      <div v-else class="content" :class="{'content-center': moduleInfo.name === '联系我们'}" v-html="$options.filters.NEWLINE(item.content)" />
    </van-col>
  </van-row>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Col, Row, Swipe, SwipeItem } from 'vant'

  export default {
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
    },
    props: {
      displayColumn: {
        type: Number,
        default: 3
      },
      moduleInfo: {
        type: Object,
        default: () => {}
      },
      moduleData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        showControls: [],
        imgAll: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      if(!this.moduleData.find((c) => c.type !== 1) && this.moduleData.length>1) {
        this.imgAll = true;
      }
      this.moduleData.forEach((item, index) => {
        this.showControls[index] = false;
      });
    },
    methods: {
      onPlay(obj, index) {
        if(this.$refs[`media${obj.id}`] && this.$refs[`media${obj.id}`][0].paused) {
          this.$refs[`media${obj.id}`][0].play();
          this.$set(this.showControls, index, true);
        }
      }
    }
  }
</script>
