<template>
  <swiper v-if="![1027, 1029].includes(uccnInfo.id)" :options="swiperOption" class="job-swiper">
    <swiper-slide v-for="(child, key) in list" :key="key">
      <van-row :class="rowClass" gutter="30">
        <van-col v-for="(item, inx) in child" :key="inx" :span="displayColumn === 3 ? 8 : 6">
          <slot :item="item"></slot>
        </van-col>
      </van-row>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
  <swiper v-else :options="yfSwiperOption" class="job-swiper">
    <swiper-slide v-for="(child, key) in jobList" :key="key">
      <van-row :class="rowClass" gutter="30">
        <van-col :span="24">
          <slot :item="child"></slot>
        </van-col>
      </van-row>
    </swiper-slide>
    <div v-if="jobList.length > displayColumn" class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Col, Row } from 'vant'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    swiper,
    swiperSlide
  },
  props: {
    jobList: {
      type: Array,
      default: () => []
    },
    displayColumn: {
      type: Number,
      default: 3
    },
    rowClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    list() {
      const result = [];
      this.jobList.forEach((item, key) => {
        item.loopKey = key;
      })
      for (let i = 0; i < this.jobList.length; i += this.displayColumn) {
        result.push(this.jobList.slice(i, i + this.displayColumn));
      }
      return result;
    },
    ...mapGetters(['uccnInfo']),
  },
  data(){
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination', // 分页指示器所在的 DOM 元素
          clickable: true // 点击分页指示器切换到对应的 slide
        },
        loop: false,
        touchRatio: 2, // 触摸滑动的顺畅度
        initialSlide: 1, // 默认展示页
        autoplay: true, // 是否自动播放
        speed: 750, // 播放速度
        slidesPerView: 'auto',
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 5, // 模块之间的间隔
        allowTouchMove: false,
        on: {
          slideChangeTransitionEnd: function() {
            // 处理手动操作轮播图后后不再自动轮播
            this.autoplay.start();
          }
        }
      },
      yfSwiperOption: {
        pagination: {
          el: '.swiper-pagination', // 分页指示器所在的 DOM 元素
          clickable: true // 点击分页指示器切换到对应的 slide
        },
        loop: false,
        touchRatio: 2, // 触摸滑动的顺畅度
        autoplay: true, // 是否自动播放
        speed: 750, // 播放速度
        paginationClickable: true,
        spaceBetween: 30, // 模块之间的间隔
        allowTouchMove: false,
        slidesPerView: 3,
        on: {
          slideChangeTransitionEnd: function() {
            // 处理手动操作轮播图后后不再自动轮播
            this.autoplay.start();
          }
        }
      }
    }
	}
}
</script>

<style lang="scss">
  .job-swiper {
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #242D37;
    }
    .swiper-pagination-bullet-active {
      background-color: #333 !important;
    }
  }
</style>