<template>
  <div class="h5-container h5-delivery-container">
    <sub-head-navs title="投递进展"></sub-head-navs>
    <van-radio-group v-model="firstChoice">
      <sub-refresh-list
        :list="recordList"
        :loading="isLoading"
        :error="error"
        :finished="isFinished"
        :refreshing="refreshing"
        @changeRefreshing="val => refreshing = val"
        @changeLoading="val => isLoading = val"
        @changeError="val => error = val"
        @load="onLoad"
        @refresh="onRefresh()">
        <sub-item slot-scope="{item}" :item="item" :totalRecords="recordList.length" @setFirstId="changeDesire" />
      </sub-refresh-list>
    </van-radio-group>
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { RadioGroup, Toast } from 'vant'
  import subHeadNavs from '@/components/h5/headNavs'
  import subItem from './submodule/item'
  import subRefreshList from '@/components/h5/refreshList'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [RadioGroup.name]: RadioGroup,
      subHeadNavs,
      subItem,
      subRefreshList,
      popupShareMenus
    },
    data() {
      return {
        isLoading: false,
        isLoading: false,
        isFinished: false,
        refreshing: false,
        error: false,
        recordList: [],
        searchParams: {
          current: 1,
          pageSize: 15
        },
        firstChoice: ''
      }
    },
    provide() {
      return {
        pageFrom: 'delivery'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryDatas();
    },
    methods: {
      queryDatas(current = 1) {
        if(current === 1 || this.refreshing) {
          this.recordList = [];
          this.refreshing = false;
        }
        this.searchParams.current = current;
        this.error = false;
        this.$http.get('/api/internal/recom/person/delivery/process.json', {params: {
          websiteId: this.uccnInfo.id,
          ...this.searchParams
        }}).then(data => {
          if(data) {
            if(this.refreshing) {
              this.refreshing = false;
            }
            if(current === 1) {
              this.recordList = data.list;
            } else {
              this.recordList.push(...data.list);
            }
            this.recordList.forEach(item => {
              if(item.firstChoice) {
                this.firstChoice = item.id;
              }
            });
            this.searchParams.current++;
            if(this.recordList.length >= data.totalRecords) {
              this.isFinished = true;
            }
          } else {
            this.isFinished = true;
          }
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.isLoading = false;
        });
      },
      onLoad() {
        if(!this.refreshing) {
          this.queryDatas(this.searchParams.current);
        }
      },
      onRefresh() {
        this.isFinished = false;
        this.isLoading = true;
        this.queryDatas();
      },
      changeDesire(val) {
        this.$http.post('/api/internal/recom/person/recommend/first/choice.json', {
          websiteId: this.uccnInfo.id,
          id: val
        }).then(data => {
          if(data) {
            Toast('第一志愿设置成功');
          }
        });
      }
    }
  }
</script>
