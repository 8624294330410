<template>
  <van-row class="self-define-wrap content-item-center" gutter="30">
    <van-col v-for="(item, key) in moduleData" :key="key" :span="24/(displayColumn || 1)"  :class="{'two-column': displayColumn === 2, 'three-column': displayColumn === 3, 'four-column': displayColumn === 4}">
      <!-- 1图片 2视频 3文字 -->
      <div v-if="item.type === 1" class="column-item img">
        <van-swipe v-if="item.fileDtoList && item.fileDtoList.length" class="self-img-swipe" :autoplay="3000" indicator-color="white" lazy-render>
          <van-swipe-item v-for="(ele, index) in item.fileDtoList" :key="index"><img :src="ele.filePath"></van-swipe-item>
        </van-swipe>
      </div>
      <div v-else-if="item.type === 2 && item.fileDtoList && item.fileDtoList.length" class="column-item video">
        <video :ref="`media${item.id}`" width="auto" height="640" loop :poster="item.videoPageDto ? item.videoPageDto.filePath : ''"
          :src="item.fileDtoList[0].filePath">
        </video>
        <p class="dialog-mask">
          <i class="h5pp h5pp-icon-audio-pause" @click="onPlay(item)"></i>
        </p>
      </div>
      <div v-else class="column-item content"  v-html="$options.filters.NEWLINE(item.content)" />
    </van-col>
    <popup-video :dialogData="dialogData"></popup-video>
  </van-row>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Col, Row, Swipe, SwipeItem } from 'vant'
  import popupVideo from '@/components/pc/dialog/video'

  export default {
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      popupVideo
    },
    props: {
      displayColumn: {
        type: Number,
        default: 1
      },
      moduleData: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
    },
    data() {
      return {
        dialogData: { isShow: false },
      }
    },
    methods: {
      onPlay(obj) {
        Object.assign(this.dialogData, {
          isShow: true,
          filePath: obj.fileDtoList[0].filePath,
          posterPath: obj.videoPageDto ? obj.videoPageDto.filePath : ''
        });
      }
    }
  }
</script>

