<template>
  <div class="absence-box" :class="{phone: $utils.isPhone()}">
    <img src="@/assets/images/img_found.png" class="img-found">
    <p>该网站目前正在建设中</p>
  </div>
</template>

<script>
  import './index.scss'

  export default {
    
  }
</script>