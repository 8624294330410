<template>
  <div class="recommend-record-item" @click="viewEliminate(item)">
    <div class="record-info">
      <span>
        <span class="name el-sl">
          {{ item.resName }}
        </span>
        <span v-if="activeTab !== 0" class="status">{{ RewardStage[item.rewardItem] }}</span>
        <span v-if="activeTab === 0 && item.remarks && item.remarks.length" class="mark-tip" @click="showRemarks(item.remarks)" ><span>有人@我</span><i class="h5pp h5pp-icon-arrows-right"></i></span>
      </span>
      <span v-if="activeTab === 0 && item.result" class="job-status" :class="{'eliminate': item.recomResult === 3}">
        {{ item.result }}
        <i v-if="item.recomResult === 3 && item.elimateType" class="h5pp h5pp-icon-arrows-right"></i>
      </span>
    </div>
    <div class="other-news">
      <span v-if="activeTab === 0" class="el-sl">投递：
        <span>{{ item.jobName || '投递时未选择职位' }}</span>
      </span>
      <span v-else-if="activeTab === 2">奖金：
        <span class="count">+{{ item.amount | MORECOUNT }}元</span>
      </span>
      <span v-else-if="activeTab === 1">积分：
        <span class="count">+{{ item.amount | MORECOUNT }}积分</span>
      </span>
      <span class="create-time">{{ item.createTime | DATE('YYYY-MM-DD') }}</span>
    </div>
    <van-popup v-if="showMarkList" v-model="showMarkList" round position="bottom" teleport="body">
      <ul class="mark-list">
        <li v-for="(item, key) in list" :key="key">
          <div>
            <span class="name">{{ item.accountName }}</span>
            <span class="time">{{ item.createTime | DATE('YYYY-MM-DD HH:mm') }}</span>
          </div>
          <p class="content">{{ item.content }}</p>
        </li>
      </ul>
      <div class="popup-bottom">
        <div class="ok-btn" @click="showMarkList = false">好的</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Popup } from 'vant'
  import { RewardStage } from '@/enums/EnumRecommend'

  export default {
    components: {
      [Popup.name]: Popup,
    },
    props: {
      activeTab: [Number, String],
      item: Object
    },
    data() {
      return {
        RewardStage,
        showMarkList: false,
        list: []
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    methods: {
      showRemarks(list) {
        this.showMarkList = true;
        this.list = list || [];
      },
      viewEliminate(obj) {
        if(!obj.elimateType) {
          return;
        }
        if(obj.recomResult === 3 && obj.elimateType) {
          this.$emit('onReason', obj.elimateType)
        }
      }
    }
  }
</script>
