<template>
  <div class="container">
    <sub-head-navs v-if="menuDtoList && menuDtoList.length" :activePage="`#${menuDtoList[0].id}`" @onSearch="onSearch"></sub-head-navs>
    <div ref="homeWrap" class="home-wrap">
      <div class="page-content-item" :class="{ 'adjective-yf-school': uccnInfo.id === 1029, 'adjective-yf-society': uccnInfo.id === 1027 }">
        <div v-for="(ele, index) in moduleDtoList" :key="ele.id" 
          class="content-item-box" 
          :class="{
            'content-item-box-fff': index%2 === 1, 
            'no-padding': ele.type === 4 && ele.moduleContentList && uccnInfo.style === 2 && ele.displayColumn === 1 && ele.moduleContentList && ele.moduleContentList[0].layoutType === 2,
            'train-moudle': uccnInfo.id === 1029 && ele.name === '新员工培训',
            'staff-moudle': uccnInfo.id === 1029 && ele.name === '员工福利',
            'process-moudle': uccnInfo.id === 1029 && ele.name === '招聘流程',
            'contact-moudle': uccnInfo.id === 1029 && ele.name === '联系我们' || uccnInfo.id === 1027 && ele.name === '联系我们'
          }">
          <a :id="ele.id" href="javascript:;" class="target-fix"></a>
          <div v-if="ele.showFlag && ele.type !== 1" class="title-box wow animate__fadeInUp">
            <h5 v-if="ele.name" class="title-name">{{ ele.name }}</h5>
            <h5 v-if="ele.englishName" class="english-name">{{ ele.englishName.toUpperCase() }}</h5>
          </div>
          <!-- 首页 -->
          <div v-if="ele.type === 1" class="swipe-img-box" :class="{ 'not-fullscreen': ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].backgroundMode }">
            <div class="box-blank"></div>
            <div class="box-inner-wrap">
              <van-swipe v-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].type === 1 && ele.moduleContentList[0].fileDtoList && ele.moduleContentList[0].fileDtoList.length" ref="homeSwipe" class="item-img-swipe" :autoplay="3000">
                <van-swipe-item v-for="(ele, key) in ele.moduleContentList[0].fileDtoList" :key="key"><img :src="ele.filePath"></van-swipe-item>
              </van-swipe>
              <video v-else-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].type === 2 && ele.moduleContentList[0].videoPageDto" :poster="ele.moduleContentList[0].videoPageDto ? ele.moduleContentList[0].videoPageDto.filePath : ''" muted width="auto" height="640" autoplay loop>
                <source :src="ele.moduleContentList[0].fileDtoList[0].filePath">
              </video>
              <img v-else class="fullscreen-default" :class="{ 'not-fullscreen-default': ele.moduleContentList && ele.moduleContentList[0].backgroundMode }" src="@/assets/images/home/img_home_default.jpg" />
              <van-field v-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].searchBoxShowType === 1" class="input-search wow animate__fadeInUp" v-model.trim="keyWord" left-icon="search" placeholder="搜索职位关键字">
                <template #button>
                  <p-button :width="132" type="primary" @click="onSearch(keyWord)">搜索职位</p-button>
                </template>
              </van-field>
              <div v-if="ele.moduleContentList && !ele.moduleContentList[0].backgroundMode" class="swipe-down-tip">
                <i class="h5pp h5pp-icon-arrows-down"/>
              </div>
            </div>
          </div>
          <!-- 职位列表 -->
          <div v-if="ele.type === 2" class="content-item-center wow animate__fadeInUp" :class="{'job-content-wrap': ele.type === 2 && ele.cssFlag !== 2}">
            <div class="job-list-box" :class="{'selfdefine-job-list': ele.cssFlag === 2}">
              <div class="job-list-inner">
                <!-- 简约商务支持轮播 -->
                <template v-if="+uccnInfo.style === 2">
                  <!-- 职位列表-分类自定义样式 -->
                  <template v-if="ele.cssFlag === 2 && ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length">
                    <sub-column-container :jobList="ele.moduleJobDto.jobStatisticsList" :displayColumn="ele.displayColumn || 3" :rowClass="'classify-job-list'">
                      <template slot-scope="scope">
                        <div @click.stop="onViewJobList(scope.item, ele.id)" class="col-box">
                          <div class="classify-img">
                            <img v-if="scope.item.fileDto && scope.item.fileDto.filePath" :src="scope.item.fileDto.filePath" >
                            <img v-else-if="scope.item.categoryId <=32 && scope.item.categoryId >=10" :src="require(`@/assets/images/home/positionSort/sort_${scope.item.categoryId}.png`)" />
                            <img v-else :src="require(`@/assets/images/home/randomSort/random_${(scope.item.loopKey && scope.item.loopKey <= 20) ? scope.item.loopKey : Math.floor(Math.random() * 20)}.png`)" />
                          </div>
                          <div class="classify-title">
                            <p class="classify-name el-sl">{{ scope.item.name }}</p>
                            <van-button> 
                              <span class="jobCount">{{ scope.item.jobCount }}个职位</span>
                              <span class="view" @click="clickJob">查看职位</span>
                              <i class="h5pp h5pp-icon-arrow-down-entity"></i>
                            </van-button>
                          </div>
                        </div>
                      </template>
                    </sub-column-container>
                  </template>
                  <!-- 职位列表-分类默认样式 -->
                  <template v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length">
                    <sub-column-container :jobList="ele.moduleJobDto.jobStatisticsList" :displayColumn="ele.displayColumn || 3" :rowClass="'job-list job-functions'">
                      <template slot-scope="scope">
                        <div class="item-job">
                          <p class="functions-title el-sl">{{ scope.item.name }}</p>
                          <van-button @click.stop="onViewJobList(scope.item, ele.id)"> 
                            <span class="jobCount">共{{ scope.item.jobCount }}个职位</span>
                            <span class="view">查看职位</span>
                            <i class="h5pp h5pp-icon-arrow-down-entity"></i>
                          </van-button>
                        </div>
                      </template>
                    </sub-column-container>
                  </template>
                  <!-- 职位列表-无分类 -->
                  <template v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobDtoList && ele.moduleJobDto.jobDtoList.length">
                    <sub-column-container :jobList="ele.moduleJobDto.jobDtoList" :displayColumn="ele.displayColumn || 3" :rowClass="'job-list job-info'">
                      <template slot-scope="scope">
                        <dl class="item-job" @click.stop="viewDetail(scope.item.id)">
                          <dt v-if="scope.item.jobName" :title="scope.item.jobName">
                            <h3 class="title el-sl"><i v-if="scope.item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ scope.item.jobName }}</h3>
                            <p v-if="scope.item.minSalary && scope.item.maxSalary" class="salary">
                              <template v-if="scope.item.salaryType === 1">{{ scope.item.minSalary }}K-{{ scope.item.maxSalary }}K・{{ scope.item.monthSalaryType }}<span v-if="scope.item.monthSalaryType>12">薪</span></template>
                              <template v-else>{{ scope.item.minSalary }}W-{{ scope.item.maxSalary }}W</template>
                            </p>
                          </dt>
                          <dd>
                            <ul v-if="scope.item.department || scope.item.categoryId" class="job-divide-info clearfix">
                              <li v-if="scope.item.categoryId" class="functions el-sl">{{ scope.item.categoryId | FUNCTION }}</li>
                              <li v-if="scope.item.department" class="functions el-sl">{{ scope.item.department }}</li>
                              <li v-if="scope.item.jobRankNames" class="rank el-sl">{{ scope.item.jobRankNames.join(',') }}</li>
                              <li v-if="scope.item.jobClassifyName" class="classify el-sl">{{ scope.item.jobClassifyName }}</li>
                            </ul>
                            <ul v-if="scope.item.workYear || scope.item.eduRequire || scope.item.city" class="job-divide-info clearfix">
                              <li v-if="scope.item.eduRequire">{{ scope.item.eduRequire | JOBDEGREE }}</li>
                              <li v-if="scope.item.city" class="city el-sl">{{ scope.item.city | CITY }}</li>
                            </ul>
                            <ul v-if="scope.item.demand || scope.item.modifyTime" class="job-between-info">
                              <li v-if="scope.item.demand" class="demand">招聘人数：{{ scope.item.demand }}人</li>
                              <li v-if="scope.item.modifyTime" class="create-date">发布时间：{{ scope.item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                            </ul>
                          </dd>
                        </dl>
                      </template>
                    </sub-column-container>
                  </template>
                </template>
                <!-- 默认 -->
                <template v-else>
                  <!-- 职位列表-分类自定义样式 -->
                  <van-row v-if="ele.cssFlag === 2 && ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length" class="classify-job-list" gutter="30">
                    <van-col v-for="(item, key) in ele.moduleJobDto.jobStatisticsList.slice(0, 9)" :key="key" :span="24/(ele.displayColumn || 3)"
                      :class="{'four-column': ele.displayColumn === 4, 'three-column': ele.displayColumn === 3}">
                      <div @click.stop="onViewJobList(item, ele.id)" class="col-box">
                        <div class="classify-img">
                          <img v-if="item.fileDto && item.fileDto.filePath" :src="item.fileDto.filePath" >
                          <img v-else-if="item.categoryId <=32 && item.categoryId >=10" :src="require(`@/assets/images/home/positionSort/sort_${item.categoryId}.png`)" />
                          <img v-else src="@/assets/images/home/img_job_default.jpg" />
                        </div>
                        <div class="classify-title">
                          <p class="classify-name el-sl">{{ item.name }}</p>
                          <van-button> 
                            <span class="jobCount">{{ item.jobCount }}个职位</span>
                            <span class="view">查看职位</span>
                            <i class="h5pp h5pp-icon-arrow-down-entity"></i>
                          </van-button>
                        </div>
                      </div>
                    </van-col>
                  </van-row>
                  <!-- 职位列表-分类默认样式 -->
                  <van-row v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length" class="job-list job-functions" gutter="30">
                    <van-col v-for="(item, key) in ele.moduleJobDto.jobStatisticsList.slice(0, 9)" :key="key" :span="24/(ele.moduleJobDto.jobStatisticsList.length>3 ? 3 : ele.moduleJobDto.jobStatisticsList.length)">
                      <div class="item-job">
                        <p class="functions-title el-sl">{{ item.name }}</p>
                        <van-button @click.stop="onViewJobList(item, ele.id)"> 
                          <span class="jobCount">共{{ item.jobCount }}个职位</span>
                          <span class="view">查看职位</span>
                          <i class="h5pp h5pp-icon-arrow-down-entity"></i>
                        </van-button>
                      </div>
                    </van-col>
                  </van-row>
                  <!-- 职位列表-无分类 -->
                  <van-row v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobDtoList && ele.moduleJobDto.jobDtoList.length" class="job-list job-info" gutter="30">
                    <van-col v-for="(item, key) in ele.moduleJobDto.jobDtoList.slice(0, 9)" :key="key" span="8">
                      <dl class="item-job" @click.stop="viewDetail(item.id)">
                        <dt v-if="item.jobName" :title="item.jobName">
                          <h3 class="title el-sl"><i v-if="item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ item.jobName }}</h3>
                          <p v-if="item.minSalary && item.maxSalary" class="salary">
                            <template v-if="item.salaryType === 1">{{ item.minSalary }}K-{{ item.maxSalary }}K・{{ item.monthSalaryType }}<span v-if="item.monthSalaryType>12">薪</span></template>
                            <template v-else>{{ item.minSalary }}W-{{ item.maxSalary }}W</template>
                          </p>
                        </dt>
                        <dd>
                          <ul v-if="item.department || item.categoryId" class="job-divide-info clearfix">
                          <!-- <ul v-if="item.categoryId" class="job-divide-info clearfix"> -->
                            <li v-if="item.categoryId" class="functions el-sl">{{ item.categoryId | FUNCTION }}</li>
                            <li v-if="item.department" class="functions el-sl">{{ item.department }}</li>
                            <li v-if="item.jobRankNames" class="rank el-sl">{{ item.jobRankNames.join(',') }}</li>
                            <li v-if="item.jobClassifyName" class="classify el-sl">{{ item.jobClassifyName }}</li>
                          </ul>
                          <ul v-if="item.workYear || item.eduRequire || item.city" class="job-divide-info clearfix">
                            <!-- <li v-if="item.workYear">{{ item.workYear | JOBWORKYEARS }}</li> -->
                            <li v-if="item.eduRequire">{{ item.eduRequire | JOBDEGREE }}</li>
                            <li v-if="item.city" class="city el-sl">{{ item.city | CITY }}</li>
                          </ul>
                          <ul v-if="item.demand || item.modifyTime" class="job-between-info">
                            <li v-if="item.demand" class="demand">招聘人数：{{ item.demand }}人</li>
                            <li v-if="item.modifyTime" class="create-date">发布时间：{{ item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                          </ul>
                        </dd>
                      </dl>
                    </van-col>
                  </van-row>
                </template>
                <div class="btns-box">
                  <a v-if="publishFlag === 1" :href="`/job/list?websiteId=${uccnInfo.id}`" target="_blank">
                    <van-button class="view-all-btn" :width="240" type="primary">查看全部职位</van-button>
                  </a>
                  <van-button v-else class="view-all-btn" :width="240" type="primary">查看全部职位</van-button>
                </div>
              </div>
            </div>
          </div>
          <!-- 快速投递简历 -->
          <div v-if="ele.type === 3" class="delivery-resume-box content-item-center wow animate__fadeInUp" :class="{'no-title': !ele.showFlag }">
            <p v-if="ele.moduleContentList && ele.moduleContentList[0].content" class="desc" v-html="$options.filters.NEWLINE(ele.moduleContentList[0].content)"></p>
            <div class="btns-box">
              <p-button :width="240" type="primary" @click.stop="deliveryResume(ele.resLibraryId)">{{ ele.buttonName || '快速投递' }}</p-button>
            </div>
          </div>
          <!-- 自定义模块 -->
          <template v-if="ele.type === 4 && ele.moduleContentList">
            <div v-if="uccnInfo.style === 2 && ele.displayColumn === 1 && ele.moduleContentList && ele.moduleContentList[0].layoutType === 2" class="business-img">
              <img v-if="ele.moduleContentList[0].layoutPcPicDto" :src="ele.moduleContentList[0].layoutPcPicDto.filePath">
            </div>
            <sub-self-define-module v-else class="wow animate__fadeInUp" :moduleData="ele.moduleContentList" :moduleInfo="ele" :displayColumn="ele.displayColumn" :templateStyle="uccnInfo.style"></sub-self-define-module>
          </template>
        </div>
        <div class="powered-box wow animate__fadeInUp">
          <img src="@/assets/images/img_powered.png" alt="">
        </div>
      </div>
    </div>
    <popup-login-process :loginObj="loginObj" :identityObj="identityObj"></popup-login-process>
  </div>
</template>

<script>
  import 'animate.css'
  import './index.scss'
  import { mapGetters } from 'vuex'
  import subHeadNavs from '@/components/pc/headNavs'
  import html2canvas from 'html2canvas'
  import { Swipe, SwipeItem, Col, Row, Button, Field } from 'vant'
  import { local } from '@/utils'
  import { WOW } from 'wowjs'
  import subSelfDefineModule from './submodules/selfDefineModule'
  import subColumnContainer from './submodules/columnContainer'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'

  export default {
    components: {
      subHeadNavs,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      [Col.name]: Col,
      [Row.name]: Row,
      [Button.name]: Button,
      [Field.name]: Field,
      subSelfDefineModule,
      subColumnContainer,
      popupLoginProcess
    },
    props: {
      menuDtoList: {
        type: Array,
        default: () => []
      },
      publishFlag: Number,
      moduleDtoList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || '';
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      }
    },
    data() {
      return {
        keyWord: '',
        authInfo: {},
        loginObj: { isShow: false },
        identityObj: { isShow: false }
      }
    },
    created() {
      if(!this.mobile && local.get('bindPhoneFlag')) {
        Object.assign(this.loginObj, {
          type: 3,
          isShow: true
        })
      }
    },
    mounted() {
      this.$nextTick (() => { // 在 dom 渲染完后，再执行动画
        var wow = new WOW({
          boxClass: 'wow',  //需要执行动画的元素的 class
          animateClass: 'animate__animated', //animation.css 动画的 class
          offset: 0,  // 距离可视区域多少开始执行动画
          mobile: true,  //是否在移动设备上执行动画
          live: true //异步加载的内容是否有效
        })
        wow.init()
      });

      // 定位
      if(this.$route.hash) {
        location.href = this.$route.fullPath;
      }
      if(this.uccnInfo.publishFlag) {
        this.onUpdateWebBanner();
      }
      if(this.isRecommendWebsite) {
        this.queryAuthConfig();
        this.$utils.sendStat('0064', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId
        });
        // 注册操作先登录再认证
        if(this.$route.query.pageFrom === 'register') {
          this.loginObj.isShow = true;
        } else if(this.$route.query.pageFrom === 'recommend' && !this.mobile && !this.email) {
          this.loginObj.isShow = true;
        } else if(this.$route.query.pageFrom === 'recommend' && (this.mobile || this.email)) {
          this.$router.push({ name: 'recommend' });
        } else if(this.$route.query.pageFrom === 'register' && !(JSON.parse(local.get('user')|| '{}')).personId) {
          this.identityObj.isShow = true;
        }
      }
    },
    methods: {
      viewDetail(id) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 查看职位前
        if(this.authInfo.triggerType === 2 && !this.mobile && !this.email) {
          return this.loginObj.isShow = true;
        }
        if(this.publishFlag !== 1 ) {
          return;
        }
        const { href } = this.$router.resolve({
          name: 'jobDetail',
          params: { id },
          query: { websiteId: this.uccnInfo.id }
        });
        window.open(href, '_blank');
      },
      onSearch(data) {
        if(this.publishFlag !== 1 ) {
          return;
        }
        this.keyWord = data;
        const { href } = this.$router.resolve({
          name: 'jobList',
          query: { 
            keyWord: this.keyWord,
            websiteId: this.uccnInfo.id
          }
        });
        window.open(href, '_blank');
      },
      // 更新官网封面图
      onUpdateWebBanner() {
        setTimeout(() => {
          this.$nextTick(() => {
            const dom = this.$refs['homeWrap'];
            html2canvas(dom, {
              useCORS: true, //（图片跨域相关）
              allowTaint: false, //允许跨域（图片跨域相关）
              taintTest: false,
              background: null
            }).then(canvas => {
              const dataURL = canvas.toDataURL('image/png');
              this.uploadBanner(dataURL);
            });
          })
        }, 500);
      },
      uploadBanner(dataURL) {
        const formData = new FormData();
        const blob = this.$utils.dataURItoBlob(dataURL);
        formData.append('companyId', this.uccnInfo.companyId);
        formData.append('source', 26);
        formData.append('file', blob, Date.now() + '.jpg');
        this.$http.post('/api/file/upload.json', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }).then(res => {
          this.updatePreviewImg(res.id);
        })
      },
      updatePreviewImg(id) {
        this.$http.post('/api/website/preview/update.json', {
          websiteId: this.uccnInfo.id,
          preview: id
        }).then(() => {
          console.log('更新官网封面图成功');
        })
      },
      // 获取配置信息
      queryAuthConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfo = data;
          }
        })
      },
      clickJob() {
        console.log('clickJob---->');
      },
      onViewJobList(ele, moduleId) {
        console.log('iddddd---->', ele, moduleId);
        if(this.publishFlag !== 1) return;
        this.$router.push({
          name: 'jobList',
          query: {
            websiteId: this.uccnInfo.id,
          },
          params: {
            categoryId: ele.categoryId,
            jobClassifyId: ele.jobClassifyId,
            jobRankId: ele.jobRankId,
            cityCode: ele.cityCode,
            orgId: ele.orgId,
            companyWorkSpaceId: ele.companyWorkSpaceId,
            moduleId
          }
        });
      },
      // 快速投递简历
      deliveryResume(resLibraryId) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 查看职位前
        if(!this.mobile && !this.email) {
          return this.loginObj.isShow = true;
        }
        this.$router.push({
          name: 'ResumeAdd',
          query: {
            resLibraryId,
            websiteId: this.uccnInfo.id
          }
        })
      }
    }
  }
</script>