<template>
  <div class="recommend-main">
    <van-loading v-if="isLoading" class="loading" color="#0055DC" />
    <template v-if="isLoaded">
      <sub-h5-recommend v-if="$utils.isPhone()" :recommendRecord="recommendRecord"></sub-h5-recommend>
      <sub-pc-recommend v-else :recommendRecord="recommendRecord"></sub-pc-recommend>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Loading } from 'vant'
  import subH5Recommend from '@/views/submodule/h5/recommend'
  import subPcRecommend from '@/views/submodule/pc/recommend'

  export default {
    components: {
      [Loading.name]: Loading,
      subH5Recommend,
      subPcRecommend
    },
    data() {
      return {
        isLoading: false,
        isLoaded: false,
        recommendRecord: {
          rewardCnt: 0,
          pointCnt: 0,
          pageViewCnt: 0,
          deliverCnt: 0,
          entryCnt: 0
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.querySummary();
    },
    methods: {
      querySummary() {
        this.isLoading = true;
        this.$http.get('/api/internal/recom/person/summary.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          this.isLoaded = true;
          if(data) {
            Object.assign(this.recommendRecord, {
              rewardCnt: data.rewardCnt || 0,
              pointCnt: data.pointCnt || 0,
              pageViewCnt: data.pageViewCnt	|| 0,
              deliverCnt: data.deliverCnt || 0,
              entryCnt: data.entryCnt || 0
            });
          }
        }).finally(() => {
          this.isLoading = false;
        });
      },
    }
  }
</script>
