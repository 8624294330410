<template>
  <div class="delivery-record-item">
    <div class="record-info" @click="$router.push({ name: 'jobDetail', params: { id: item.jobId }, query: { websiteId: uccnInfo.id, recommendId: item.recommendId }})">
      <span class="name el-sl">
        {{ item.jobName }}
      </span>
      <span v-if="item.resultName" class="status" :class="{'eliminate': item.stageId === 3}">
        {{ item.resultName }}
      </span>
    </div>
    <div class="record-info">
      <span class="set-volunteer">
        <van-radio v-if="totalRecords > 1 && uccnInfo.schoolAmbassadorFlag && item.lockVolunteerFlag === 1 && item.recomResult !== 3" :name="item.id" icon-size="16px" checked-color="#36404A" @click="changeRadio($event, item.id)">设为第一志愿</van-radio>
      </span>
      <span class="create-time">{{ item.createTime | DATE('YYYY-MM-DD') }} 投递</span>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Radio } from 'vant'

  export default {
    components: {
      [Radio.name]: Radio
    },
    props: {
      item: Object,
      totalRecords: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapGetters({
        uccnInfo: ['uccnInfo']
      })
    },
    methods: {
      changeRadio(event, val) {
        this.$emit('setFirstId', val);
      }
    }
  }
</script>
