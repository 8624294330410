<template>
  <div class="h5-container">
    <sub-head-navs></sub-head-navs>
    <div class="home-wrap">
      <van-sticky v-if="isRecommendWebsite">
        <sub-recommend-people />
      </van-sticky>
      <div class="page-content-item" :class="{ 'adjective-yf-school': uccnInfo.id === 1029, 'adjective-yf-society': uccnInfo.id === 1027 }">
        <template v-for="ele in moduleDtoList">
          <!-- 永福股份：校招特殊定义 -->
          <div v-if="(['首页', '热招职位', '招聘流程', '联系我们'].includes(ele.name) && uccnInfo.id === 1029) || uccnInfo.id !== 1029" 
            :key="ele.id" 
            class="content-box"
            :class="{
              'process-moudle': uccnInfo.id === 1029 && ele.name === '招聘流程',
              'contact-moudle': uccnInfo.id === 1029 && ele.name === '联系我们' || uccnInfo.id === 1027 && ele.name === '联系我们'
            }"
          >
            <a href="javascript:;" class="target-fix" :id="ele.id"></a>
            <div v-if="ele.showFlag && ele.type !== 1" class="title-box">
              <h5 v-if="ele.name" class="title-name">{{ ele.name }}</h5>
              <h5 v-if="ele.englishName" class="english-name">{{ ele.englishName.toUpperCase() }}</h5>
            </div>
            <div v-if="ele.type === 1" class="swipe-img-box">
              <van-swipe v-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].type === 1 && ele.moduleContentList[0].fileDtoList && ele.moduleContentList[0].fileDtoList.length" :autoplay="3000" indicator-color="white" class="home-img-swipe" lazy-render>
                <van-swipe-item v-for="(ele, key) in ele.moduleContentList[0].fileDtoList" :key="key"><img :src="ele.filePath"></van-swipe-item>
              </van-swipe>
              <video v-else-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].type === 2 && ele.moduleContentList[0].videoPageDto" 
                id="video-dom"
                :poster="ele.moduleContentList[0].videoPageDto ? ele.moduleContentList[0].videoPageDto.filePath : ''"
                :src="ele.moduleContentList[0].fileDtoList[0].filePath"
                width="auto" 
                height="235"
                muted 
                autoplay 
                loop
                playsinline
                webkit-playsinline
                x5-playsinline
                x5-video-player-type="h5-page"
                t7-video-player-type="h5" 
                x5-video-orientation="landscape"
                controlslist="nodownload nofullscreen noremoteplayback">
              </video>
              <img v-else src="@/assets/images/home/img_home_default.jpg" />
              <div v-if="ele.moduleContentList && ele.moduleContentList.length && ele.moduleContentList[0].searchBoxShowType === 1" class="img-input-box" :class="{'input-search-focus': searchFlag}">
                <van-search class="input-search" v-model.trim="keyWord" placeholder="搜索职位关键字" @focus="searchFlag = true" @keyup.enter="onSearch" @blur="onSearch"></van-search>
              </div>
            </div>
            <!-- 职位列表 -->
            <template v-if="ele.type === 2">
              <div class="job-list-box" :class="{'self-define-job': ele.cssFlag === 2}">
                <div class="job-list-inner">
                  <!-- 简约商务支持轮播 -->
                  <template v-if="+uccnInfo.style === 2">
                    <!-- 职位列表-分类自定义样式 -->
                    <template v-if="ele.cssFlag === 2 && ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length">
                      <sub-column-container :jobList="ele.moduleJobDto.jobStatisticsList" :displayColumn="ele.displayColumn || 3" :rowClass="'swipe-sort'">
                        <template slot-scope="scope">
                          <div class="swipe-sort-item" @click="onViewJobList(scope.item, ele.id)">
                            <div class="sort">
                              <p class="title el-sl">{{ scope.item.name }}</p>
                              <p class="cnt">共{{ scope.item.jobCount }}个职位<i class="h5pp h5pp-icon-arrow-down-entity" /></p>
                              <i class="arrow h5pp h5pp-icon-arrow-down-entity" />
                            </div>
                            <img v-if="scope.item.fileDto && scope.item.fileDto.filePath" :src="scope.item.fileDto.filePath" />
                            <img v-else-if="scope.item.categoryId <=32 && scope.item.categoryId >=10" :src="require(`@/assets/images/home/positionSort/sort_${scope.item.categoryId}.png`)" />
                            <img v-else :src="require(`@/assets/images/home/randomSort/random_${(scope.item.loopKey && scope.item.loopKey <= 20) ? scope.item.loopKey : Math.floor(Math.random() * 20)}.png`)" />
                          </div>
                        </template>
                      </sub-column-container>
                    </template>
                    <!-- 职位列表-分类默认样式 -->
                    <template v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length">
                      <sub-column-container :jobList="ele.moduleJobDto.jobStatisticsList" :displayColumn="ele.displayColumn || 3" :rowClass="'swipe-sort'">
                        <template slot-scope="scope">
                          <div class="swipe-sort-default" @click="onViewJobList(scope.item, ele.id)">
                            <p class="title el-sl">{{ scope.item.name }}</p>
                            <p class="cnt">共{{ scope.item.jobCount }}个职位<i class="h5pp h5pp-icon-arrow-down-entity" /></p>
                          </div>
                        </template>
                      </sub-column-container>
                    </template>
                    <!-- 无职能分类，直接展示职位 -->
                    <template v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobDtoList && ele.moduleJobDto.jobDtoList.length">
                      <sub-column-container :jobList="ele.moduleJobDto.jobDtoList" :displayColumn="ele.displayColumn || 3" :rowClass="'swipe-item'">
                        <template slot-scope="scope">
                          <a @click="isLogin(scope.item.id)" href="javascript:;">
                            <dl class="swipe-job-item">
                              <dt v-if="scope.item.jobName" class="clearfix">
                                <h3 class="title el-sl"><i v-if="scope.item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ scope.item.jobName }}</h3>
                                <p v-if="scope.item.minSalary && scope.item.maxSalary" class="salary">
                                  <template v-if="scope.item.salaryType === 1">{{ scope.item.minSalary }}K-{{ scope.item.maxSalary }}K・{{ scope.item.monthSalaryType }}<span v-if="scope.item.monthSalaryType>12">薪</span></template>
                                  <template v-else>{{ scope.item.minSalary }}W-{{ scope.item.maxSalary }}W</template>
                                </p>
                              </dt>
                              <dd>
                                <ul v-if="scope.item.department || scope.item.categoryId" class="job-divide-info clearfix">
                                  <li v-if="scope.item.department" class="org-name el-sl">{{ scope.item.department }}</li>
                                  <li v-if="scope.item.categoryId" class="functions el-sl">{{ scope.item.categoryId | FUNCTION }}</li>
                                  <li v-if="scope.item.jobClassifyName" class="job-classify el-sl">{{ scope.item.jobClassifyName }}</li>
                                  <li v-if="scope.item.jobRankNames && scope.item.jobRankNames.length" class="rank el-sl">{{ scope.item.jobRankNames.join(',') }}</li>
                                  <li v-if="scope.item.demand">招聘{{ scope.item.demand }}人</li>
                                </ul>
                                <ul v-if="scope.item.workYear || scope.item.eduRequire || scope.item.city " class="job-divide-info clearfix">
                                  <li v-if="scope.item.workYear">{{ scope.item.workYear | JOBWORKYEARS }}</li>
                                  <li v-if="scope.item.eduRequire">{{ scope.item.eduRequire | JOBDEGREE}}</li>
                                  <li v-if="scope.item.city">{{ scope.item.city | CITY }}</li>
                                  <li v-if="scope.item.modifyTime" class="create-date">发布时间：{{ scope.item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                                </ul>
                              </dd>
                            </dl>
                          </a>
                        </template>
                      </sub-column-container>
                    </template>
                  </template>
                   <!-- 默认 -->
                  <template v-else>
                    <!-- 职位列表-分类自定义样式 -->
                    <van-row v-if="ele.cssFlag === 2 && ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length" class="classify-job-list" gutter="18">
                      <template v-for="(item, key) in ele.moduleJobDto.jobStatisticsList.slice(0, 9)">
                        <van-col :key="key" span="12" @click="onViewJobList(item, ele.id)">
                          <div class="classify-img">
                            <img v-if="item.fileDto && item.fileDto.filePath" :src="item.fileDto.filePath" >
                            <img v-else-if="item.categoryId <=32 && item.categoryId >=10" :src="require(`@/assets/images/home/positionSort/sort_${item.categoryId}.png`)" />
                            <img v-else src="@/assets/images/home/img_job_default.jpg" />
                          </div>
                          <div class="classify-title">
                            <p class="classify-name el-sl">{{ item.name }}</p>
                            <p class="job-count">共{{ item.jobCount }}个职位<i class="h5pp h5pp-icon-arrow-down-entity"></i></p>
                          </div>
                        </van-col>
                      </template>
                    </van-row>
                    <!-- 职位列表-分类默认样式 -->
                    <van-row v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobStatisticsList && ele.moduleJobDto.jobStatisticsList.length" class="job-list job-functions">
                      <van-col v-for="(item, key) in ele.moduleJobDto.jobStatisticsList.slice(0, 9)" :key="key" span="24">
                        <div class="item-job">
                          <div class="functions-box" @click="onViewJobList(item, ele.id)">
                            <p class="functions-title el-sl">{{ item.name }}</p>
                            <p class="job-cnt">共{{ item.jobCount }}个职位<i class="h5pp h5pp-icon-arrow-down-entity"></i></p>
                          </div>
                        </div>
                      </van-col>
                    </van-row>
                    <!-- 无职能分类，直接展示职位 -->
                    <van-row v-else-if="ele.moduleJobDto && ele.moduleJobDto.jobDtoList && ele.moduleJobDto.jobDtoList.length" class="job-list job-info">
                      <van-col v-for="(item, key) in ele.moduleJobDto.jobDtoList.slice(0, 9)" :key="key" span="24">
                        <a @click="isLogin(item.id)" href="javascript:;">
                          <dl class="item-job">
                            <dt v-if="item.jobName" class="clearfix">
                              <h3 class="title el-sl"><i v-if="item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ item.jobName }}</h3>
                              <p v-if="item.minSalary && item.maxSalary" class="salary">
                                <template v-if="item.salaryType === 1">{{ item.minSalary }}K-{{ item.maxSalary }}K・{{ item.monthSalaryType }}<span v-if="item.monthSalaryType>12">薪</span></template>
                                <template v-else>{{ item.minSalary }}W-{{ item.maxSalary }}W</template>
                              </p>
                            </dt>
                            <dd>
                              <ul v-if="item.department || item.categoryId" class="job-divide-info clearfix">
                                <li v-if="item.department" class="org-name el-sl">{{ item.department }}</li>
                                <li v-if="item.categoryId" class="functions el-sl">{{ item.categoryId | FUNCTION }}</li>
                                <li v-if="item.jobClassifyName" class="job-classify el-sl">{{ item.jobClassifyName }}</li>
                                <li v-if="item.jobRankNames && item.jobRankNames.length" class="rank el-sl">{{ item.jobRankNames.join(',') }}</li>
                                <li v-if="item.demand">招聘{{ item.demand }}人</li>
                              </ul>
                              <ul v-if="item.workYear || item.eduRequire ||item.city " class="job-divide-info clearfix">
                                <li v-if="item.workYear">{{ item.workYear | JOBWORKYEARS }}</li>
                                <li v-if="item.eduRequire">{{ item.eduRequire | JOBDEGREE}}</li>
                                <li v-if="item.city">{{ item.city | CITY }}</li>
                                <li v-if="item.modifyTime" class="create-date">发布时间：{{ item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                              </ul>
                            </dd>
                          </dl>
                        </a>
                      </van-col>
                    </van-row>
                  </template>
                  <div class="btns-box">
                    <a v-if="publishFlag === 1" :href="`/job/list?websiteId=${uccnInfo.id}`"><van-button class="view-all-btn" type="primary">查看全部职位</van-button></a>
                    <van-button v-else class="view-all-btn" type="primary">查看全部职位</van-button>
                  </div>
                </div>
              </div>
            </template>
            <!-- 快速投递简历 -->
            <div v-if="ele.type === 3" class="delivery-resume-box">
              <p v-if="ele.moduleContentList && ele.moduleContentList[0].content" class="desc" v-html="$options.filters.NEWLINE(ele.moduleContentList[0].content)"></p>
              <div class="btns-box">
                <van-button @click="deliveryResume(ele.resLibraryId)">{{ ele.buttonName || '快速投递' }}</van-button>
              </div>
            </div>
            <!-- 自定义模块 -->
            <template v-if="ele.type === 4 && ele.moduleContentList">
              <div v-if="uccnInfo.style === 2 && ele.displayColumn === 1 && ele.moduleContentList && ele.moduleContentList[0].layoutType === 2" class="business-img">
                <img v-if="ele.moduleContentList[0].layoutH5PicDto" :src="ele.moduleContentList[0].layoutH5PicDto.filePath">
              </div>
              <sub-self-define-module v-else :moduleData="ele.moduleContentList" :templateStyle="uccnInfo.style" :displayColumn="ele.displayColumn" :moduleInfo="ele"></sub-self-define-module>
            </template>
          </div>
        </template>
        <div class="powered-box">
          <img src="@/assets/images/img_powered.png" alt="">
        </div>
      </div>
    </div>
    <popup-share-menus />
  </div>
</template>

<script>
  import './index.scss'
  import wxapi from '@/utils/wxapi'
  import { mapGetters } from 'vuex'
  import { Swipe, SwipeItem, Col, Row, Field, Button, Sticky, Search } from 'vant'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/h5/headNavs'
  import subRecommendPeople from '@/components/h5/recommendPeople'
  import subSelfDefineModule from './submodules/selfDefineModule'
  import subColumnContainer from './submodules/columnContainer'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      [Col.name]: Col,
      [Row.name]: Row,
      [Field.name]: Field,
      [Button.name]: Button,
      [Sticky.name]: Sticky,
      [Search.name]: Search,
      subHeadNavs,
      subRecommendPeople,
      subSelfDefineModule,
      subColumnContainer,
      popupShareMenus
    },
    props: {
      publishFlag: Number,
      menuDtoList: {
        type: Array,
        default: () => []
      },
      moduleDtoList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        keyWord: '',
        authInfoObj: {},
        searchFlag: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      // 是否是内推官网
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      },
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || local.get('oldPersonId') ||'';
      }
    },
    created() {
      if(this.uccnInfo) {
        this.initTitle();
        this.setShareConfig();
      }
    },
    mounted() {
      this.toHashId();
      if(this.isRecommendWebsite) {
        this.$utils.sendStat('0064', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId
        });
        this.queryAuthConfig();
      }
      this.$nextTick(() => {
        this.autoPlayVideo();
      });
    },
    methods: {
      // 获取配置信息
      queryAuthConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      toHashId() {
        if(this.$route.hash) {
          this.$nextTick(() => {
            location.href = this.$route.fullPath;
          });
        }
      },
      initTitle() {
        window.document.title = this.uccnInfo.websiteTitle;
      },
      setShareConfig() {
        wxapi.wxRegister({
          title: this.uccnInfo.shareMainTitle, // 分享标题
          desc: this.uccnInfo.shareSubTitle, // 分享描述
          link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.uccnInfo.shareImageUrl, // 分享图标
          success: () => {}
        });
      },
      onClickMenus() {
        this.$router.push({
          name: 'Navigate',
          query: { websiteId: this.uccnInfo.id }
        });
      },
      isLogin(id) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(this.authInfoObj.triggerType === 2 && !this.mobile && !this.email) {
          this.$router.push({ name: 'login' });
        } else if(this.publishFlag !== 1) {
          return;
        } else {
          this.$router.push({
            name: 'jobDetail',
            query: { websiteId: this.uccnInfo.id },
            params: { id }
          });
        }
      },
      onSearch() {
        this.$router.push({
          name: 'jobList',
          query: { 
            keyWord: this.keyWord,
            websiteId: this.uccnInfo.id
          }
        });
      },
      onViewJobList(ele, moduleId) {
        if(this.publishFlag !== 1) return;
        this.$router.push({
          name: 'jobList',
          query: {
            websiteId: this.uccnInfo.id
          },
          params: {
            categoryId: ele.categoryId,
            jobClassifyId: ele.jobClassifyId,
            jobRankId: ele.jobRankId,
            cityCode: ele.cityCode,
            orgId: ele.orgId,
            companyWorkSpaceId: ele.companyWorkSpaceId,
            moduleId
          }
        })
      },
      // 快速投递简历
      deliveryResume(resLibraryId) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(!this.mobile && !this.email) {
          return this.$router.push({ name: 'login' });
        }
        this.$router.push({
          name: 'ResumeAdd',
          query: {
            resLibraryId,
            websiteId: this.uccnInfo.id
          }
        })
      },
      autoPlayVideo() {
        wxapi.wxRegister({
          success: () => {
            wx.ready(() => {
              const media = document.getElementById('video-dom');
              if(media.paused) {
                media.play();
              }
            });
          }
        });
      },
      changeSwipe() {
        this.$refs.jobSwipe[0].next();
      }
    },
    watch: {
      '$route.hash'(newV, oldV) {
        if(newV) {
          this.toHashId();
        }
      }
    }
  }
</script>