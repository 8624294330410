<template>
  <div class="home-main">
    <van-loading v-if="isLoading" class="loading" color="#0055DC" />
    <template v-if="isLoaded">
      <sub-h5-home v-if="$utils.isPhone()" :menuDtoList="menuDtoList" :publishFlag="publishFlag" :moduleDtoList="moduleDtoList"></sub-h5-home>
      <sub-pc-home v-else :menuDtoList="menuDtoList" :publishFlag="publishFlag" :moduleDtoList="moduleDtoList"></sub-pc-home>
    </template>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Loading } from 'vant'
  import { local } from '@/utils'
  import subH5Home from '@/views/submodule/h5/home'
  import subPcHome from '@/views/submodule/pc/home'

  export default {
    components: {
      [Loading.name]: Loading,
      subH5Home,
      subPcHome
    },
    data() {
      return {
        isLoading: false,
        isLoaded: false,
        menuDtoList: [],
        publishFlag: 0 //是否发布 1是；2否
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
    },
    created() {
      // this.websiteId = this.$route.query.id || this.uccnInfo; // 仅用于预览
      this.queryData();
      this.checkCareerTalkIsActived();
    },
    methods: {
      queryData() {
        let companyPath = this.$route.params.pathMatch,
        websiteId = this.$route.query.id;
        if(!companyPath) {
          this.$router.replace({
            name: 'Absence'
          });
          return;
        }
        this.isLoading = true;
        this.$http.get('/api/website/main/info.json', {params: {
          url: companyPath,
          websiteId: websiteId
        }}).then(data => {
          this.isLoaded = true;
          if(data) {
            // 如果新打开页面其他公司清除登录信息 同一公司的不同类型官网也清除登录信息
            if(local.get('user') && (data.companyId !== (JSON.parse(local.get('user') || '{}')).companyId || data.type !== (JSON.parse(local.get('user') || '{}')).type)) {
              this.$store.commit('CLEAR_USER_INFO');
            }
            // 如果打开其他公司了需要清除之前的oldPersonId
            if(data.companyId !== (JSON.parse(local.get('uccnInfo') || '{}')).companyId && local.get('oldPersonId')) {
              local.remove('oldPersonId');
            }
            this.$store.commit('SET_ROOT_PATH', companyPath);
            this.menuDtoList = data.menuDtoList;
            this.publishFlag = data.publishFlag;
            this.$store.commit('SET_UCCN_INFO', data);
            this.moduleDtoList = data.moduleDtoList || [];
          }
        }).catch(err => {}).finally(() => {
          this.isLoading = false;
        });
      },
      /* 验证宣讲会现在生效还是失效 */
      async checkCareerTalkIsActived(careerTalkId, websiteId) {
        //假如传过来careerTalkId ，需要存在storage里，提交简历的时候取出来传过去
        if(this.$route.query && this.$route.query.careerTalkId) {
          //还需要做校验，宣讲会失效时 跳到404页面
          try {
            let res = await this.$http.get('/api/career/talk/website/check.json', {params: {
              careerTalkId:this.$route.query.careerTalkId, 
              websiteId:this.$route.query.websiteId
            }})
            if(res) {
              localStorage.setItem('careerTalkId', this.$route.query.careerTalkId);
            } else {
              this.$router.replace('/404');
            }
          } catch(e) {
            this.$router.replace('/404');
          }
        } 
      }
    }
  }
</script>