<template>
  <div class="main-page h5-delivery-success">
    <sub-head-navs v-if="websiteId>0" title="投递"></sub-head-navs>
    <div class="delivery-box">
       <img src="@/assets/images/img_delivery_success2.png">
      <h5>投递成功</h5>
    </div>
    <div v-if="qrcodeUrl" class="qrcode-box">
      <img :src="qrcodeUrl">
      <p class="tips">扫描或长按识别二维码添加HR企业微信</p>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import subHeadNavs from '@/components/h5/headNavs'

  export default {
    components: {
      subHeadNavs
    },
    data() {
      return {
        qrcodeUrl: ''
      }
    },
    computed: {
      websiteId() {
        return this.$route.query.websiteId || '';
      },
      jobId() {
        return this.$route.query.jobId || '';
      }
    },
    created() {
      this.queryQrcode();
    },
    methods: {
      queryQrcode() {
        this.$http.get('/api/website/job/contact/qrcode.json', {params: {
          jobId: this.jobId,
          websiteId: this.websiteId
        }}).then(data => {
          if(data) {
            this.qrcodeUrl = data;
          }
        });
      }
    }
  }
</script>
