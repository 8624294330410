<template>
  <div class="delivery-main">
    <van-loading v-if="isLoading" class="loading" color="#0055DC" />
    <template v-if="isLoaded">
      <sub-h5-delivery v-if="$utils.isPhone()"></sub-h5-delivery>
      <sub-pc-delivery v-else ></sub-pc-delivery>
    </template>
  </div>
</template>

<script>
  import './index.scss'
  import { Loading } from 'vant'
  import subH5Delivery from '@/views/submodule/h5/delivery'
  import subPcDelivery from '@/views/submodule/pc/delivery'

  export default {
    components: {
      [Loading.name]: Loading,
      subH5Delivery,
      subPcDelivery
    },
    data() {
      return {
        isLoading: false,
        isLoaded: true
      }
    }
  }
</script>