<template>
  <sub-h5-delivery-success v-if="$utils.isPhone()"></sub-h5-delivery-success>
  <sub-pc-delivery-success v-else></sub-pc-delivery-success>
</template>

<script>
  import subH5DeliverySuccess from '@/views/submodule/h5/deliverySuccess'
  import subPcDeliverySuccess from '@/views/submodule/pc/deliverySuccess'
  export default {
    components: {
      subH5DeliverySuccess,
      subPcDeliverySuccess
    }
  }
</script>