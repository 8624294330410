<template>
  <div v-if="recommendPeople && Object.keys(recommendPeople).length" class="recommend-people">
    <span class="person">{{ recommendPeople.name }} <span v-if="recommendPeople.name">分享的职位</span></span>
    <a v-if="!personId" href="javascript:;" @click="isLogin">我也要做内推荐人，去登录<i class="h5pp h5pp-icon-arrows-right"></i></a>
    <p class="tip">认证后分享的职位，您将获得内推奖励</p>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'

  export default {
    data() {
      return {
        recommendPeople: {}
      }
    },
    inject: {
      pageFrom: {
        default: ''
      },
      jobId: {
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        uccnInfo: ['uccnInfo']
      }),
      personId() {
        return (JSON.parse(local.get('user') || '{}')).personId || '';
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
    },
    created() {
     if(this.personId || this.$route.query.personId) {
        this.queryUserInfo();
      }
    },
    methods: {
      queryUserInfo() {
          this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId || this.$route.query.personId
        } }).then(data => {
          if(data) {
            this.recommendPeople = data;
          }
        });
      },
      isLogin() {
        if(!this.mobile && !this.email) {
          this.$router.push({ name: 'login', query: { pageFrom: this.pageFrom, jobId: this.jobId, isRecommendTop: true }});
        } else {
          this.$router.push({ name: 'identity', query: { jobId: this.jobId, pageFrom: this.pageFrom }});
        }
      }
    }
  }
</script>
