<template>
  <sub-h5-login v-if="$utils.isPhone()"></sub-h5-login>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'
  import subH5Login from '@/views/submodule/h5/login'

  export default {
    components: {
      subH5Login
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      // 注册入口进来的获取websiteId
      if(!this.uccnInfo) {
        this.queryData();
      }
    },
    methods: {
      queryData() {
        let companyPath = this.$route.params.pathMatch,
            websiteId = this.$route.query.id; // 仅用于预览
        if(!companyPath) {
          this.$router.replace({
            name: 'Absence'
          });
          return;
        }
        this.$http.get('/api/website/main/info.json', {params: {
          url: companyPath,
          websiteId: websiteId
        }}).then(data => {
          if(data) {
            // 如果新打开页面其他公司清除登录信息 同一公司的不同类型官网也清除登录信息
            if(local.get('user') && (data.companyId !== (JSON.parse(local.get('user') || '{}')).companyId || data.type !== (JSON.parse(local.get('user') || '{}')).type)) {
              this.$store.commit('CLEAR_USER_INFO');
            }
            // 如果打开其他公司了需要清除之前的oldPersonId
            if(data.companyId !== (JSON.parse(local.get('uccnInfo') || '{}')).companyId && local.get('oldPersonId')) {
              local.remove('oldPersonId');
            }
            this.$store.commit('SET_ROOT_PATH', companyPath);
            this.$store.commit('SET_UCCN_INFO', data);
          }
        });
      }
    }
  }
</script>
