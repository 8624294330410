<template>
  <div class="container main-page pc-recommend-container">
    <sub-head-navs />
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ name: 'Home', query: { id: uccnInfo.id }, params: {pathMatch}}">招聘首页</el-breadcrumb-item>
        <el-breadcrumb-item>推荐数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="recommend-statistical">
      <div class="recommend-box">
        <ul class="earnings">
          <li>
            <span class="text"><i class="h5pp h5pp-icon-integral"></i>已获积分</span>
            <span class="count">{{ recommendRecord.pointCnt | MORECOUNT }}</span>
          </li>
          <li>
            <span class="text"><i class="h5pp h5pp-icon-money"></i>已获奖金（元）</span>
            <span class="count">{{ recommendRecord.rewardCnt | MORECOUNT }}</span>
          </li>
        </ul>
        <ul class="numbers">
          <li>
            <span class="text"><i class="h5pp h5pp-icon-traffic"></i>访问量</span>
            <span class="count">{{ recommendRecord.pageViewCnt | MORECOUNT }}</span>
          </li>
          <li>
            <span class="text"><i class="h5pp h5pp-icon-delivery"></i>投递量</span>
            <span class="count">{{ recommendRecord.deliverCnt | MORECOUNT }}</span>
          </li>
          <li>
            <span class="text"><i class="h5pp h5pp-icon-employ"></i>录用量</span>
            <span class="count">{{ recommendRecord.entryCnt | MORECOUNT }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="recommend-tab-box">
      <el-tabs class="recommend-tab" :value="activeTab" @tab-click="onChangTab">
        <el-tab-pane v-for="arr in Object.entries(RecommendTabs)" :key="arr[0]" :name="arr[0]" :label="arr[1]">
          <el-table
            class="recommend-table"
            :data="recordList"
            style="width: 100%">
            <el-table-column
              prop="resName"
              key="resName"
              label="候选人"
              width="300">
            </el-table-column>
            <el-table-column
              v-if="activeTab === '0'"
              class-name="job-name"
              prop="jobName"
              key="jobName"
              label="投递职位"
              :show-overflow-tooltip="true"
              width="300">
              <template slot-scope="scope">
                <a v-if="scope.row.jobName" href="javascript:;">{{ scope.row.jobName }}</a>
                <span v-else>投递时未选择职位</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              key="createTime"
              label="投递时间">
              <template slot-scope="scope">{{ scope.row.createTime | DATE('YYYY-MM-DD') }}</template>
            </el-table-column>
            <el-table-column
              label="当前阶段"
              prop="current"
              key="current">
              <template slot-scope="scope">
                <span v-if="activeTab === '0'">{{ scope.row.result }}</span>
                <span v-else>{{ RewardStage[scope.row.rewardItem] || '--' }}</span>
                <span v-if="activeTab === '0' && scope.row.recomResult === 3 && scope.row.elimateType" class="reason">原因：{{ OutRefuseType[scope.row.elimateType] }}</span>
              </template>
            </el-table-column>
            
            <el-table-column
              v-if="activeTab === '0'"
              prop="remark"
              key="remark"
              width="125"
              label="">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.remarks && scope.row.remarks.length"
                  placement="top-end"
                  title=""
                  width="540"
                  popper-class="pc-popper-mark-list"
                  trigger="hover">
                  <div class="popover-inner">
                    <ul class="mark-list">
                      <li v-for="(item, key) in scope.row.remarks" :key="key">
                        <div>
                          <span class="name">{{ item.accountName }}</span>
                          <span class="time">{{ item.createTime | DATE('YYYY-MM-DD HH:mm') }}</span>
                        </div>
                        <p class="content">{{ item.content }}</p>
                      </li>
                    </ul>
                  </div>
                  <div slot="reference" class="mark-tips"><i class="h5pp h5pp-icon-warn"></i>有人@我</div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeTab === '2'"
              prop="amount"
              key="amount"
              label="奖金">
              <template slot-scope="scope">
                <span v-if="scope.row.amount">{{ scope.row.amount | MORECOUNT }}元</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeTab === '1'"
              prop="amount"
              key="amount"
              label="积分">
              <template slot-scope="scope">
                <span v-if="scope.row.amount">{{ scope.row.amount | MORECOUNT }}积分</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-box">
            <el-pagination
              @current-change="onChangePage"
              :current-page.sync="searchParams.current"
              :page-size="searchParams.pageSize"
              :total="totalRecords"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Breadcrumb, BreadcrumbItem, Tabs, TabPane, Table, TableColumn, Pagination, Popover } from 'element-ui'
  import { local } from '@/utils'
  import { OutRefuseType, RewardStage, RecommendTabs } from '@/enums/EnumRecommend'
  import subHeadNavs from '@/components/pc/headNavs'

  export default {
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
      [Popover.name]: Popover,
      subHeadNavs
    },
    props: {
      recommendRecord: Object
    },
    data() {
      return {
        RewardStage,
        OutRefuseType,
        RecommendTabs,
        isLoading: false,
        searchParams: {
          current: 1,
          pageSize: 10,
        },
        recordList: [],
        totalRecords: 0,
        activeTab: '0'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
        pathMatch() {
        return local.get('rootPath') || '';
      }
    },
    created() {
      this.queryData();
    },
    methods: {
      queryData(current = 1) {
        this.searchParams.current = current;
        this.isLoading = true;
        if(this.activeTab !== '0') {
          Object.assign(this.searchParams,{
            type: this.activeTab
          });
        } else {
          delete this.searchParams.type;
        }
        let apiUrl = this.activeTab === '0' ? '/api/internal/recom/person/recommend/list.json' : '/api/internal/recom/person/reward/list.json'
        this.$http.get(apiUrl, {params: {
          websiteId: this.uccnInfo.id,
          ...this.searchParams
        }}).then(data => {
          if(data) {
            this.recordList = (data || {}).list || [];
            this.totalRecords = (data || {}).totalRecords || 0;
          }
        }).finally(() => {
          this.isLoading = false;
        });
      },
      onChangTab(obj) {
        this.activeTab = obj.name;
        this.queryData();
      },
      onChangePage(index) {
        this.queryData(index);
      },
    }
  }
</script>
