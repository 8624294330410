<template>
  <div class="h5-container h5-recommend-container">
    <sub-head-navs title="推荐数据"></sub-head-navs>
    <div class="record-box">
      <div class="top-record">
        <div>
          <span>{{ recommendRecord.pointCnt | MORECOUNT }}</span>
          <span>已获积分</span>
        </div>
        <div>
          <span>{{ recommendRecord.rewardCnt | MORECOUNT }}</span>
          <span>已获奖金（元）</span>
        </div>
      </div>
      <ul class="bottom-record">
        <li>
          <span>{{ recommendRecord.pageViewCnt | MORECOUNT }}</span>
          <span>访问量</span>
        </li>
        <li>
          <span>{{ recommendRecord.deliverCnt | MORECOUNT }}</span>
          <span>投递量</span>
        </li>
        <li>
          <span>{{ recommendRecord.entryCnt | MORECOUNT }}</span>
          <span>录用量</span>
        </li>
      </ul>
    </div>
    <van-tabs class="recommend-tab" v-model="activeTab"  @change="queryDatas()">
      <van-tab v-for="arr in Object.entries(RecommendTabs)" :key="+arr[0]" :title="arr[1]">
        <sub-refresh-list
          :list="recordList"
          :loading="isLoading"
          :error="error"
          :finished="isFinished"
          :refreshing="refreshing"
          @changeRefreshing="val => refreshing = val"
          @changeLoading="val => isLoading = val"
          @changeError="val => error = val"
          @load="onLoad"
          @refresh="onRefresh()">
          <sub-recommend-item slot-scope="{item}" :activeTab="activeTab" :item="item" @onReason="onReason" />
        </sub-refresh-list>
      </van-tab>
    </van-tabs>
    <popup-text-sheet v-if="eliminateObj.isShow" :dialogData="eliminateObj"></popup-text-sheet>
    <popup-share-menus />
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Tab, Tabs } from 'vant';
  import { RecommendTabs } from '@/enums/EnumRecommend'
  import subHeadNavs from '@/components/h5/headNavs'
  import subRecommendItem from './components/recommendItem'
  import subRefreshList from '@/components/h5/refreshList'
  import subEmpty from '@/components/h5/empty'
  import popupTextSheet from '@/components/h5/textSheet'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      subHeadNavs,
      subRecommendItem,
      subRefreshList,
      subEmpty,
      popupTextSheet,
      popupShareMenus
    },
    props: {
      recommendRecord: Object
    },
    data() {
      return {
        RecommendTabs,
        isLoading: false,
        isFinished: false,
        refreshing: false,
        error: false,
        recordList: [],
        activeTab: 0,
        searchParams: {
          current: 1,
          pageSize: 15,
          websiteId: ''
        },
        eliminateObj: {
          isShow: false,
          title: '淘汰原因'
        }
      }
    },
    provide() {
      return {
        pageFrom: 'recommend'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      if(this.uccnInfo.id) {
        Object.assign(this.searchParams, {
          websiteId: this.uccnInfo.id
        });
      }
      this.queryDatas();
    },
    methods: {
      queryDatas(current = 1) {
        if(current === 1 || this.refreshing) {
          this.recordList = [];
          this.refreshing = false;
        }
        this.searchParams.current = current;
        this.error = false;
        if(this.activeTab) {
          Object.assign(this.searchParams, {
            type: this.activeTab
          });
        } else {
          delete this.searchParams.type;
        }
        this.isLoading = true;
        let apiUrl = !this.activeTab ? '/api/internal/recom/person/recommend/list.json' : '/api/internal/recom/person/reward/list.json'
        this.$http.get(apiUrl, {params: 
          this.searchParams
        }).then(data => {
          if(data && data.list && data.list.length) {
            if(this.refreshing) {
              this.refreshing = false;
            }
            if(current === 1) {
              this.recordList = data.list;
            } else {
              this.recordList.push(...data.list);
            }
            this.searchParams.current++;
            if(this.recordList.length >= data.totalRecords) {
              this.isFinished = true;
            } else {
              this.isFinished = false;
            }
          } else {
            this.isFinished = true;
          }
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.isLoading = false;
        });
      },
      onLoad() {
        if(!this.refreshing) {
          this.queryDatas(this.searchParams.current);
        }
      },
      onRefresh() {
        this.isFinished = false;
        this.isLoading = true;
        this.queryDatas();
      },
      onReason(elimateType) {
        Object.assign(this.eliminateObj, {
          isShow: true,
          elimateType
        });
      }
    }
    
  }
</script>
