<template>
  <el-dialog :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="video-modal" @close="dialogData.isShow = false">
    <video controls width="auto" height="640" loop autoplay :poster="dialogData.posterPath || ''"
      :src="dialogData.filePath">
    </video>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { Dialog } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog
    },
    methods: {
      onClose() {
        location.reload();
      }
    }
  }
</script>
